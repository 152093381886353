<template lang="pug">
FcwModal.FcwGeolocationModal(
  :width="464"
  :title="t('profile__geolocation_modal__title')"
  persistent
)
  .FcwGeolocationModal__title {{ t('profile__geolocation_modal__text') }}

  template(#actions="{ close }")
    FButton(
      background="neutral--light-5"
      color="neutral--dark-3"
      outlined
      @click="handleSubmit(false, close)"
    )
      span {{ t('common__deny') }}
    FButton(@click="handleSubmit(true, close)")
      span {{ t('common__accept') }}
</template>

<style lang="stylus">
.FcwGeolocationModal
  &__title
    white-space pre-line
</style>

<script setup lang="ts">
import { OptInType } from '@/fifteen-sdk/enums';

const api = useApi();
const { t } = useClientI18n();

const { track } = useUserTracking();

const userStore = useUserStore();

function handleSubmit(optInGeolocation: boolean, close: () => void): void {
  api.patch('/user', {
    body: {
      optIns: {
        geolocation: optInGeolocation,
      },
    },
  });

  const optInGeolocationConsentDate = userStore?.user?.opt_ins?.find(
    optIn => optIn.type === OptInType.GeolocationSharing
  )?.last_validated;

  track.consentToGpsDataStorage({
    consentValue: optInGeolocation,
    consentDate: optInGeolocationConsentDate,
    consentLastUpdate: new Date().toUTCString(),
  });

  close();
}
</script>
