<template lang="pug">
.FcwSidebar(:style="style")
  .FcwSidebar__item(
    v-for="(link, index) in links"
    :key="index"
  )
    FcwRouterLink.FcwSidebar__link(
      ghost
      :location="link.location"
      :active-class="!link.exact ? 'FcwSidebar__link--active' : ''"
      :exact-active-class="link.exact ? 'FcwSidebar__link--active' : ''"
      hide-arrow
      color="neutral--dark-2"
    ) {{ link.title }}
</template>

<style lang="stylus">
.FcwSidebar
  display flex
  flex-direction column
  align-items flex-start
  padding rem(8)

  > * + *
    margin-top rem(40)

.FcwSidebar__item
  padding-left rem(48)

.FcwSidebar__link
  color var(--FcwSidebar--textColor)
  padding rem(4)

  &::before
    content ''
    position absolute
    background var(--FcwSidebar--activeTextColor)
    width rem(16)
    height rem(4)
    left rem(-56)
    transform 'translateX(%s)' % rem(-32)
    transition transform 300ms ease-out
    border-top-right-radius rem(8)
    border-bottom-right-radius rem(8)

  &.FcwSidebar__link--active
    color var(--FcwSidebar--activeTextColor)
    font-weight 700

    &::before
      transform translateX(rem(0))
</style>

<script setup lang="ts">
import type { FcwRouterLinkProps } from '@/components/molecules/FcwRouterLink.vue';

export interface FcwSidebarLink {
  /**
   * Title of the link
   */
  title: string;
  /**
   * Location of the link
   */
  location: FcwRouterLinkProps['location'];
  /**
   * If the active hint must be visible only if location is exact
   */
  exact?: boolean;
}

export interface FcwSidebarProps {
  /**
   * Link text color
   */
  textColor?: Color;
  /**
   * Active item text color
   */
  activeTextColor?: Color;
  /**
   * Sidebar links
   */
  links?: FcwSidebarLink[];
}

const props = withDefaults(defineProps<FcwSidebarProps>(), {
  textColor: 'neutral--light-2',
  activeTextColor: 'primary--light-2',
  links: () => [],
});

const style = computed<Style>(() => ({
  '--FcwSidebar--textColor': getCssColor(props.textColor),
  '--FcwSidebar--activeTextColor': getCssColor(props.activeTextColor),
}));
</script>
